<template>
    <v-container fluid class="mb-12">
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                                >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                </div>
            </v-col>

            <v-col cols="12">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Start Date </span>
                                <v-menu ref="startDateModal" v-model="startDateModal" transition="scale-transition"
                                    offset-y min-width="auto" max-width="290px" :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="ma-0 mt-2 border-12" v-model="startDate" dense clearable outlined
                                            persistent-hint append-icon="mdi-calendar"
                                            v-bind="attrs" v-on="on" hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="startDate"
                                        no-title @input="startDateModal = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">End Date </span>
                                <v-menu ref="endDateModal"
                                v-model="endDateModal"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        dense
                                        clearable
                                        outlined
                                        v-model="endDate"
                                        persistent-hint
                                        append-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 mt-2 border-12"
                                        hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="endDate"
                                        no-title @input="endDateModal = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-btn class="mt-4 border-12" style="padding: 19px;" color="info" elevation="2" small @click="getData()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mb-2">
            <v-col cols="12">
                <v-card outlined class="pa-md-5">  
                    <!-- <h6 class="cyan-text text-darken-3 m-0">Perfomance</h6> -->
                    <div id="container1" style="height: 640px; width: 100%;"></div>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mb-2">
            <v-col cols="12" v-if="tableVisible === true">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    dense
                                    :headers="header"
                                    :items="dataSet"
                                    :loading="$store.state.overlay"
                                    item-class="tr_datatable"
                                    :items-per-page="5"
                                    fixed-header
                                    height="200"
                                    :divider="true"
                                    :light="true"
                                >
                                    <template v-slot:top>
                                        <v-toolbar flat color="white">
                                            <div class="d-flex w-100">
                                                <p class="cyan-text text-darken-3 m-0 hidden-mobile" style="font-size: large;">{{tableTitle}} Date Capture: {{dateCapture}}</p>
                                                <!-- <v-spacer></v-spacer> -->
                                                <!-- <v-text-field outlined style="max-width: 300px;" class="border-12 mr-3 mt-2" v-model="searchItem" append-icon="mdi-magnify" dense  hide-details></v-text-field> -->
                                                <!-- <v-btn class="border-12" style="padding: 19px;" small color="success" elevation="2" @click="openDialog('new', [])"><v-icon>mdi-plus</v-icon> New PMT </v-btn> -->
                                            </div>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:[`item.action`]="{ item }">
                                        <div class="mt-0">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon small class="ma-0" @click="tableClick(item)" v-on="on">
                                                        mdi-eye
                                                    </v-icon>
                                                </template>
                                                <span>Show</span>
                                            </v-tooltip>
                                        </div>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mb-2">
            <v-col cols="12" v-if="tableVisibleDetail === true">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    :headers="headerDetail"
                                    :items="dataSetDetail"
                                    :loading="$store.state.overlay"
                                    item-class="tr_datatable"
                                    :items-per-page="10"
                                    fixed-header
                                    dense
                                    height="450"
                                    :divider="true"
                                    :light="true"
                                    :search="searchDetailItem"
                                >
                                    <template v-slot:top>
                                        <v-toolbar flat color="white">
                                            <div class="d-flex w-100">
                                                <p class="cyan-text text-darken-3 m-0 hidden-mobile" style="font-size: large;">{{tableTitle}} Date Capture: {{dateCapture}}</p>
                                                <v-spacer></v-spacer>
                                                <v-text-field outlined style="max-width: 300px;" class="border-12 mr-3 mt-2" v-model="searchDetailItem" append-icon="mdi-magnify" dense  hide-details></v-text-field>
                                                <v-btn small
                                                    color="#005c37"
                                                    class="py-5 mr-3 text-white border-12"
                                                    @click="exportExcel()"><v-icon>mdi-file-excel</v-icon> Export Excel</v-btn>
                                            </div>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:[`item.description`]="{ item }">
                                        <div class="mt-0">
                                            {{ item.description.trim() }}
                                        </div>
                                    </template>
                                    <template v-slot:[`item.dt_pr`]="{ item }">
                                        <div class="mt-0">
                                            {{ new Date((new Date(new Date(item.dt_pr) - (new Date()).getTimezoneOffset() *
                                                    60000)).toISOString()
                                            ).toLocaleString('en-US', {
                                                month: 'short',
                                                day: '2-digit',
                                                year: 'numeric'
                                            }) }}
                                        </div>
                                </template>
                                <template v-slot:[`item.dt_aprv1`]="{ item }">
                                    <div class="mt-0">
                                        {{ new Date((new Date(new Date(item.dt_aprv1) - (new Date()).getTimezoneOffset() *
                                                60000)).toISOString()
                                        ).toLocaleString('en-US', {
                                            month: 'short',
                                            day: '2-digit',
                                            year: 'numeric'
                                        }) }}
                                    </div>
                                </template>
                                <template v-slot:[`item.dt_aprv2`]="{ item }">
                                    <div class="mt-0">
                                        {{ new Date((new Date(new Date(item.dt_aprv2) - (new Date()).getTimezoneOffset() *
                                                60000)).toISOString()
                                        ).toLocaleString('en-US', {
                                            month: 'short',
                                            day: '2-digit',
                                            year: 'numeric'
                                        }) }}
                                    </div>
                                </template>
                                <template v-slot:[`item.qty`]="{ item }">
                                    <div class="mt-0">
                                        {{ $store.getters.convertToCurrencyNoFrac(item.qty) }}
                                    </div>
                                </template>
                                <template v-slot:[`item.qty2`]="{ item }">
                                    <div class="mt-0">
                                        {{ $store.getters.convertToCurrencyNoFrac(item.qty2) }}
                                    </div>
                                </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { purchasingPerfomance } from "../../../backend-api/sm/purchasing/purchasingPerfomance";
    import exportFromJSON from "export-from-json"
    export default{
        data(){
            return{
                snackbar: {
                    color: null,
                    icon: null,
                    mode: null,
                    position: "top",
                    text: null,
                    timeout: 7500,
                    title: null,
                    visible: false
                },
                breadcumbs: [
                    {
                        text: 'Sunrise Mill',
                        disabled: false,
                        href: '/admin/sm',
                    },
                    {
                        text: 'Purchasing',
                        disabled: true,
                        href: 'breadcrumbs_link_2',
                    },
                    {
                        text: 'Purchasing Perfomance',
                        disabled: true,
                        href: 'breadcrumbs_link_2',
                    },
                ],
                startDateModal:false,
                startDate:'',
                endDateModal:false,
                endDate:'',
                header:[
                    {text: 'Action', align: 'center', value: 'action'},
                    // { text: 'Date Capture', value: 'dt_cap'},
                    { text: 'Purchaser', value: 'purchase'},
                    { text: 'Total Item', value: 'total_pr_item'},
                    { text: 'Total Header', value: 'total_pr'}
                ],
                dataSet:[],
                tableTitle:'',
                tableTitleDetail:'',
                tableVisible :false,
                tableVisibleDetail :false,
                headerDetail:[
                    // { text: 'Date Capture', value: 'dt_cap'},
                    { text: 'Date PR', value: 'dt_pr'},
                    { text: 'Purchaser', value: 'purchase'},
                    { text: 'PR No', value: 'pr_id'},
                    { text: 'PR Item', value: 'pr_item'},
                    { text: 'Description', value: 'description'},
                    { text: 'Qty', value: 'qty'},
                ],
                dataSetDetail:[],
                searchDetailItem:'',
                dateCapture:'',
                exportToExcelLists: [],
            }
        },
        computed: {
        },
        mounted(){
        
        },
        methods:{
            addSymbols(e) {
                var suffixes = ["", "K", "M", "B"];
                var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

                if(order > suffixes.length - 1)
                    order = suffixes.length - 1;

                var suffix = suffixes[order];
                return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix
            },
            toggleDataSeries(e) {
                console.log(e)
                if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                    e.dataSeries.visible = false;
                } else {
                    e.dataSeries.visible = true;
                }
                e.chart.render();
            },
            getChart(datas, container){
                var self = this;

                var chart = new CanvasJS.Chart(container, {
                    animationEnabled: true,
                    theme: "light2",
                    backgroundColor: "transparent",
                    exportEnabled: true,
                    axisY: {
                        title: "Total Item",
                        crosshair: {
                            enabled: true,
                            snapToDataPoint: true
                        },
                        labelFormatter: this.addSymbols,
                        // logarithmic: true,
                        // logarithmBase: 2
                    },
                    axisX:{
                        labelFontSize: 12,
                        labelFontWeight: "bold",
                    },
                    toolTip:{
                        shared:true,
                        yValueFormatString: "##0.00",
                    },
                    legend: {
                        cursor: "pointer",
                        itemclick: this.toggleDataSeries,
                        horizontalAlign: "center",
                        verticalAlign: "bottom",
                    },
                    data: datas
                });
                chart.render();
            },
            async getData(){
                var startDate = this.startDate ? new Date(this.startDate).toISOString().slice(0, 10) : ''
                var endDate = this.endDate ? new Date(this.endDate).toISOString().slice(0, 10) : ''
                if(startDate == ''){
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        text: "Start date is a must.",
                        visible: true
                    };
                }
                this.$store.dispatch('setOverlay', true)
                let reqBody = {
                    "dt_cap_start" : startDate,
                    "dt_cap_end" : endDate ? endDate : ''
                }
                const respData = await purchasingPerfomance.fetchIndex('', reqBody)
                if (respData.status === 200) {
                    const dataResp = respData.data.data.data1
                    const dataResp1 = respData.data.data.data2

                    const poO = dataResp.filter(item => item.tr_type === "PO" && item.status === "O")
                        .map(item => ({ label: item.dt_cap, y: item.total_pr}));
                    const pohO = dataResp1.filter(item => item.tr_type === "PO" && item.status === "O")
                        .map(item => ({ label: item.dt_cap, y: item.total_pr }));

                    const poC = dataResp.filter(item => item.tr_type === "PO" && item.status !== "O")
                        .map(item => ({ label: item.dt_cap, y: item.total_pr}));
                    const pohC = dataResp1.filter(item => item.tr_type === "PO" && item.status !== "O")
                        .map(item => ({ label: item.dt_cap, y: item.total_pr }));

                    const prO = dataResp.filter(item => item.tr_type === "PR" && item.status === "O")
                        .map(item => ({ label: item.dt_cap, y: item.total_pr}));
                    const prhO = dataResp1.filter(item => item.tr_type === "PR" && item.status === "O")
                        .map(item => ({ label: item.dt_cap, y: item.total_pr }));

                    const prC = dataResp.filter(item => item.tr_type === "PR" && item.status !== "O")
                        .map(item => ({ label: item.dt_cap, y: item.total_pr}));
                    const prhC = dataResp1.filter(item => item.tr_type === "PR" && item.status !== "O")
                        .map(item => ({ label: item.dt_cap, y: item.total_pr }));
                    var resp = {
                        "PO Item Open": poO,
                        "PO Open": pohO,
                        "PO Item Close": poC,
                        "PO Close": pohC,
                        "PR Item Open": prO,
                        "PR Open": prhO,
                        "PR Item Close": prC,
                        "PR Close": prhC,
                    }
                    for (let key in resp) {
                        resp[key] = resp[key].map(item => {
                            return {
                                ...item,
                                y: parseFloat(item.y)
                            };
                        });
                    }
                    resp = Object.keys(resp).sort().reduce((result, key) => {
                        result[key] = resp[key];
                        return result;
                    }, {});

                    var data =[];
                    for (let key in resp) {
                        data.push({
                            type: 'line',
                            indexLabel: "{y}",
                            // indexLabelFontSize: 8,
                            // indexLabelFontColor: "#FFF",
                            click: this.chartClick,
                            name: key,
                            showInLegend: true,
                            visible: true,
                            indexLabelPlacement: "inside",
                            indexLabelOrientation: "horizontal",
                            yValueFormatString: "#,###.##",
                            dataPoints: resp[key]
                        })
                    }
                    console.log(data)
                    this.$store.dispatch('setOverlay', false)
                    this.getChart(data, container1)
                } else {
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        text: respData.data.data,
                        visible: true
                    };
                    this.$store.dispatch('setOverlay', false)
                }
            },
            async chartClick(a){
                console.log(a)
                console.log(a.dataSeries.name)
                console.log(a.dataPoint.label)
                const chartName = a.dataSeries.name
                const dateCap = a.dataPoint.label
                this.dateCapture = a.dataPoint.label
                let reqBody = {}
                let respData = ''
                this.$store.dispatch('setOverlay', true)
                switch (chartName) {
                    case 'PR Open':
                        reqBody = {
                            'dt_cap': dateCap,
                            'status': 'O'
                        }
                        
                        this.tableTitle = chartName
                        respData = await purchasingPerfomance.fetchData('', reqBody)
                        this.handle(respData)
                    break;
                    case 'PR Close':
                        reqBody = {
                            'dt_cap': dateCap,
                            'status': 'C'
                        }
                        
                        this.tableTitle = chartName
                        respData = await purchasingPerfomance.fetchData('', reqBody)
                        this.handle(respData)
                    break;
                    case 'PO Open':
                        reqBody = {
                            'dt_cap': dateCap,
                            'status': 'O'
                        }
                        
                        this.tableTitle = chartName
                        respData = await purchasingPerfomance.fetchDataPo('', reqBody)
                        this.handle(respData)
                    break;
                    case 'PO Close':
                        reqBody = {
                            'dt_cap': dateCap,
                            'status': 'C'
                        }
                        this.tableTitle = chartName
                        respData = await purchasingPerfomance.fetchDataPo('', reqBody)
                        this.handle(respData)
                    break;
                    default:
                    this.$store.dispatch('setOverlay', false)
                }                
                
            },
            handle(respData){
                if (respData.status === 200) {
                    console.log(respData.data.data)
                    this.dataSet = respData.data.data
                    this.tableVisible = true
                    this.$store.dispatch('setOverlay', false)
                } else {
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        text: respData.data.data,
                        visible: true
                    };
                    this.$store.dispatch('setOverlay', false)
                }
            },
            async tableClick(e){
                // console.log(e)
                this.tableVisibleDetail = true
                let reqBody = {
                    "dt_cap" : e.dt_cap,
                    "status" : e.status,
                    "purchase" : e.purchase
                }
                let respData = ''
                this.$store.dispatch('setOverlay', true)
                switch (this.tableTitle) {
                    case 'PR Open':
                        this.headerDetail = [
                            // { text: 'Date Capture', value: 'dt_cap'},
                            { text: 'Date PR', value: 'dt_pr'},
                            { text: 'Purchaser', value: 'purchase'},
                            { text: 'PR No', value: 'pr_id'},
                            { text: 'PR Item', value: 'pr_item'},
                            { text: 'Description', value: 'description'},
                            { text: 'PR Memo', value: 'memo_txt'},
                            { text: 'Date Approve 1', value: 'dt_aprv1'},
                            { text: 'Approve 1 by', value: 'aprv1_by'},
                            { text: 'Date Approve 2', value: 'dt_aprv2'},
                            { text: 'Approve 2 by', value: 'aprv2_by'},
                            { text: 'Qty PR', value: 'qty'},
                            { text: 'Qty PO', value: 'qty2'},
                        ],
                        this.tableTitleDetail = 'PR Open Detail - ' + e.purchase
                        respData = await purchasingPerfomance.fetchDetailPr('', reqBody)
                        this.handleDetail(respData)
                    break;
                    case 'PR Close':
                        this.headerDetail = [
                            // { text: 'Date Capture', value: 'dt_cap'},
                            { text: 'Date PR', value: 'dt_pr'},
                            { text: 'Purchaser', value: 'purchase'},
                            { text: 'PR No', value: 'pr_id'},
                            { text: 'PR Item', value: 'pr_item'},
                            { text: 'Description', value: 'description'},
                            { text: 'PR Memo', value: 'memo_txt'},
                            { text: 'Date Approve 1', value: 'dt_aprv1'},
                            { text: 'Approve 1 by', value: 'aprv1_by'},
                            { text: 'Date Approve 2', value: 'dt_aprv2'},
                            { text: 'Approve 2 by', value: 'aprv2_by'},
                            { text: 'Qty PR', value: 'qty'},
                            { text: 'Qty PO', value: 'qty2'},
                        ],
                        this.tableTitleDetail = 'PR Close Detail - ' + e.purchase
                        respData = await purchasingPerfomance.fetchDetailPr('', reqBody)
                        this.handleDetail(respData)
                    break;
                    case 'PO Open':
                        this.headerDetail = [
                            // { text: 'Date Capture', value: 'dt_cap'},
                            { text: 'Date PO', value: 'dt_pr'},
                            { text: 'Purchaser', value: 'purchase'},
                            { text: 'PO No', value: 'pr_id'},
                            { text: 'PO Item', value: 'pr_item'},
                            { text: 'Description', value: 'description'},
                            { text: 'PO Memo', value: 'memo_txt'},
                            { text: 'Date Approve 1', value: 'dt_aprv1'},
                            { text: 'Date Approve 2', value: 'dt_aprv2'},
                            { text: 'Qty PO', value: 'qty'},
                            { text: 'Qty Rcv', value: 'qty2'},
                        ],
                        this.tableTitleDetail = 'PO Open Detail - ' + e.purchase
                        respData = await purchasingPerfomance.fetchDetailPo('', reqBody)
                        this.handleDetail(respData)
                    break;
                    case 'PO Close':
                        this.headerDetail = [
                            // { text: 'Date Capture', value: 'dt_cap'},
                            { text: 'Date PO', value: 'dt_pr'},
                            { text: 'Purchaser', value: 'purchase'},
                            { text: 'PO No', value: 'pr_id'},
                            { text: 'PO Item', value: 'pr_item'},
                            { text: 'Description', value: 'description'},
                            { text: 'PO Memo', value: 'memo_txt'},
                            { text: 'Date Approve 1', value: 'dt_aprv1'},
                            { text: 'Date Approve 2', value: 'dt_aprv2'},
                            { text: 'Qty PO', value: 'qty'},
                            { text: 'Qty Rcv', value: 'qty2'},
                        ],
                        this.tableTitleDetail = 'PO Close Detail - ' + e.purchase
                        respData = await purchasingPerfomance.fetchDetailPo('', reqBody)
                        this.handleDetail(respData)
                    break;
                    default:
                    this.$store.dispatch('setOverlay', false)
                }
            },
            handleDetail(respData){
                if (respData.status === 200) {
                    // console.log(respData.data.data)
                    this.dataSetDetail = respData.data.data
                    this.tableVisibleDetail = true
                    this.$store.dispatch('setOverlay', false)
                } else {
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        text: respData.data.data,
                        visible: true
                    };
                    this.$store.dispatch('setOverlay', false)
                }
            },
            async exportExcel(){
                const data = this.exportToExcelLists
                const fileName = "SMPurchasePerfomanceData"
                const exportType = exportFromJSON.types.csv

                if (data) exportFromJSON({ data, fileName, exportType })

                Swal.fire({
                    title: 'Success',
                    text: 'Data exported successfully, check your download folder.'
                })
            },
        }
    }
</script>